import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'Select',
  mixins: [Base],
  props: {
    hasFlag: Boolean,
    value: [String, Object, Array, Number],
    options: Array,
    refId: String,
    id: String,
    fieldClass: String,
    placeholder: String,
    optionValue: String,
    optionLabel: String,
    optionImg: String,
    optionsClass: String,
    color: {
      type: String,
      default: 'info'
    },
    bgColor: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: Boolean,
      default: false
    },
    label: String,
    error: String,
    standout: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedValue: this.value
    };
  },
  methods: {
    resetFocus: function resetFocus() {
      this.$refs[this.refId].blur();
    }
  },
  watch: {
    value: function value() {
      if (this.selectedValue !== this.value) this.selectedValue = this.value;
    },
    selectedValue: function selectedValue() {
      this.$emit('input', this.selectedValue);
      this.resetFocus();
    }
  }
};