import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'SocialAuth',
  mixins: [Base],
  props: {
    socialLinks: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    entryPoint: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  computed: {
    typeEntrance: function typeEntrance() {
      return this.$store.getters['auth/typeEntrance'];
    },
    isMobileLayout: function isMobileLayout() {
      return !this.isDesktop;
    },
    sortedSocialLinks: function sortedSocialLinks() {
      var socialList = this.socialLinks;
      return socialList.sort(function (a, b) {
        return b.name.localeCompare(a.name);
      });
    },
    getPrefix: function getPrefix() {
      return this.isMobileLayout ? this.entryPoint === 'login' ? this.$t('auth.login') : this.$t('auth.signup') : '';
    }
  },
  methods: {
    handleClickBtn: function handleClickBtn(socialName) {
      this.$store.dispatch('auth/setTypeEntrance', socialName);
      this.setCookie();
      this.$store.gaRegister.clickButton({
        location: 'registration_window',
        buttonName: socialName.toLowerCase()
      });
    },
    setCookie: function setCookie() {
      this.$store.$cookies.set('userFirstVisit', true);
    }
  }
};