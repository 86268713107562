import "core-js/modules/es6.regexp.match.js";
var detect = {
  isMacOs: function isMacOs() {
    return /(Mac|iPhone|iPod|iPad|Pike)/i.test(navigator.userAgent) ? true : navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  },
  isWebView: function isWebView() {
    return /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(navigator.userAgent);
  },
  isMobile: function isMobile() {
    return window.matchMedia('(max-width: 1023px)').matches;
  },
  isTablet: function isTablet() {
    return window.matchMedia('(max-width: 1152px)').matches;
  },
  isMacOsMobile: function isMacOsMobile() {
    return navigator.userAgent.match(/(.+)(iPhone|iPad|iPod)(.+)OS[\s|_](\d+)_?(\d+)?[_]?(\d+)?.+/i);
  },
  iOSVersion: function iOSVersion() {
    return this.isMacOsMobile() ? /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion) : false;
  },
  isIosChrome: function isIosChrome() {
    return this.isMacOsMobile() && /CriOS/i.test(navigator.userAgent);
  },
  isIOSChromeOrIOS15: function isIOSChromeOrIOS15() {
    var iOSVersion = this.iOSVersion();
    return this.isIosChrome() || iOSVersion && +iOSVersion[1] >= 15;
  }
};
export default detect;