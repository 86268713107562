//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
export default {
  name: 'ReferralCodeBox',
  props: {
    referralData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isReferralPage: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPromoCode: false
    };
  },
  computed: {
    facebookData: function facebookData() {
      return {
        appId: 218153873842278,
        redirectUri: encodeURIComponent(this.referralData.referralUrl),
        display: 'iframe',
        href: encodeURIComponent(this.referralData.referralUrl)
      };
    }
  },
  methods: {
    shareOnFacebook: function shareOnFacebook() {
      this.$store.ga.clickButton({
        location: 'invite_friends',
        buttonName: 'share_facebook'
      });
      window.location.href = "https://www.facebook.com/dialog/share?app_id=".concat(this.facebookData.appId, "&display=").concat(this.facebookData.display, "&href=").concat(this.facebookData.href, "&redirect_uri=").concat(this.facebookData.redirectUri);
    },
    copyPromocode: function copyPromocode(promocode) {
      var _this = this;

      this.isPromoCode = true;
      this.$store.ga.clickButton({
        location: 'invite_friends',
        buttonName: 'copy'
      });
      copyToClipboard(promocode).then(function () {
        _this.callClipboardNote();
      }).catch(function () {
        console.log('copy promocode error');
      });
    },
    copyReferralLink: function copyReferralLink(linkUrl) {
      var _this2 = this;

      this.isPromoCode = false;
      this.$store.ga.clickButton({
        location: 'invite_friends',
        buttonName: 'copy'
      });
      copyToClipboard(linkUrl).then(function () {
        _this2.callClipboardNote();
      }).catch(function () {
        console.log('copyToClipboard error');
      });
    },
    callClipboardNote: function callClipboardNote() {
      var notifyPosition = 'bottom';
      this.$q.notify({
        message: this.isPromoCode ? 'Promo code copied.' : 'Link copied.',
        icon: 'img:statics/referral/invite-notify.svg',
        color: 'success',
        position: notifyPosition,
        timeout: 5000,
        classes: 'notificaton-box notificaton-box--referral'
      });
    },
    callQualifiedNote: function callQualifiedNote() {
      var notifyPosition = 'top';
      this.$q.notify({
        message: this.isPromoCode ? 'Promo code copied.' : 'Link copied.',
        icon: 'img:statics/referral/invite-notify.svg',
        color: 'success',
        position: notifyPosition,
        timeout: 5000,
        classes: 'notificaton-box notificaton-box--referral'
      });
    }
  }
};