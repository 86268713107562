//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'RegisterBanner',
  mixins: [Base],
  props: {
    promo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    welcomeGift: function welcomeGift() {
      return this.$store.getters['player/welcomeGift'];
    },
    getBackground: function getBackground() {
      if (!this.isDesktop && this.promo.bgImage) return "background-image: url(".concat(this.promo.bgImage, ")");
      return "background-color: ".concat(this.promo.bgColor);
    }
  }
};