import { render, staticRenderFns } from "./PromotionItemsPreset.vue?vue&type=template&id=35c91578&scoped=true&"
import script from "./PromotionItemsPreset.vue?vue&type=script&lang=js&"
export * from "./PromotionItemsPreset.vue?vue&type=script&lang=js&"
import style0 from "./PromotionItemsPreset.vue?vue&type=style&index=0&id=35c91578&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c91578",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpinnerDots,QDialog,QIcon});
