export default {
  methods: {
    openCashPreset: function openCashPreset() {
      this.$store.gaBannerPresetPackage.click({
        buttonName: 'make_deposit',
        quantity: this.depositsCount,
        location: this.location
      });
      this.$store.gaCash.deposit({
        location: this.location,
        type: '',
        step: 'view_payments_info',
        package: this.preset.money
      });
      this.openCash();
    },
    openCashGeneral: function openCashGeneral() {
      this.$store.ga.clickButton({
        buttonName: 'make_deposit',
        location: this.location
      });
      this.$store.gaCash.deposit({
        location: this.location,
        type: '',
        package: '',
        step: 'select_payments_package'
      });
      this.$store.$cash.open({
        url: '/cash/deposit-by-money/'
      });
    },
    openCashWinnings: function openCashWinnings() {
      this.$store.ga.clickButton({
        buttonName: 'buy_winning_balance',
        location: this.location
      });
      this.$store.gaCash.deposit({
        location: this.location,
        type: 'buy_winning',
        package: '',
        step: 'select_payments_package'
      });
      this.$store.$cash.open({
        url: '/cash/winnings-to-entries/'
      });
    }
  }
};