//
//
//
//
//
//
//
//
//
//
//
import cash from '@modules/cash/mixins/cash';
export default {
  name: 'CashWrapper',
  mixins: [cash],
  props: {
    mode: {
      type: String,
      default: 'big' // [big, small]

    },
    notClose: {
      type: Boolean,
      default: false
    }
  },
  created: function created() {
    // HOT fix: pass setting to layout (router-view)
    this.$store.dispatch('cash/setWrapperMode', this.mode);
    this.$store.dispatch('cash/setDisableCloseByCover', this.notClose);
  }
};