//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'QuestWinNotification',
  props: {
    isWhiteTheme: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uncollectedQuestPrize: function uncollectedQuestPrize() {
      return this.$store.getters['quest/uncollectedQuestPrize'];
    }
  },
  methods: {
    collectPrize: function collectPrize() {
      this.$store.$eventBus.$emit(this.$store.$events.SHOW_QUEST_WIN_ANIMATION, this.uncollectedQuestPrize.playerData.prize);
      this.$store.commit('quest/setUncollectedQuestPrize', null);
    }
  }
};