//
//
//
//
//
//
//
export default {
  name: 'GetAppPopup',
  methods: {
    closePopup: function closePopup() {
      this.$emit('close');
    }
  }
};