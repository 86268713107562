import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PAGES } from '@modules/cash/constants';
import cash from '@modules/cash/mixins/cash';
export default {
  name: 'Menu',
  mixins: [cash],
  computed: {
    menuItems: function menuItems() {
      return [{
        title: 'Donation',
        page: this.getPage,
        show: true
      }, // {
      //   title: 'Reserve',
      //   page: PAGES.redeem,
      //   show: this.showRedeem,
      // },
      {
        title: 'History',
        page: PAGES.history,
        show: this.showHistory
      }];
    },
    menuList: function menuList() {
      return this.menuItems.filter(function (_ref) {
        var show = _ref.show;
        return show === true;
      });
    },
    routeName: function routeName() {
      var routeName = this.$route.name;
      return routeName;
    },
    gameMode: function gameMode() {
      return window.$store.getters['player/gameMode'];
    },
    hasDeposits: function hasDeposits() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['cash/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasDeposits;
    },
    balance: function balance() {
      return window.$store.getters['balance/playerBalance'];
    },
    showHistory: function showHistory() {
      var _this$$store$getters$2;

      return (_this$$store$getters$2 = this.$store.getters['cash/appCash']) === null || _this$$store$getters$2 === void 0 ? void 0 : _this$$store$getters$2.isShowHistory;
    },
    showRedeem: function showRedeem() {
      var _this$balance;

      if (this.isMobileApplication) return this.hasDeposits;
      return this.gameMode === 'SweepStakes' && (this.hasDeposits || ((_this$balance = this.balance) === null || _this$balance === void 0 ? void 0 : _this$balance.entries));
    },
    getPage: function getPage() {
      var _this$balance2;

      if (this.routeName === 'winnings-to-entries') {
        return PAGES.depositEntries;
      } else if (this.routeName === 'deposit-by-money' || ((_this$balance2 = this.balance) === null || _this$balance2 === void 0 ? void 0 : _this$balance2.winnings) < 1) {
        return PAGES.depositMoney;
      } else return PAGES.deposit;
    }
  },
  methods: {
    isShowMenu: function isShowMenu() {
      this.$store.dispatch('cash/setMenuItems', this.menuList.length > 1);
    }
  },
  created: function created() {
    this.isShowMenu();
  }
};