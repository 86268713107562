//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Terms',
  props: {
    isProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text: function text() {
      return 'Terms & Conditions';
    }
  }
};