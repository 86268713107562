import "core-js/modules/es6.regexp.split.js";
//
//
//
//
//
//
import SimpleTimer from '@/mixins/SimpleTimer';
export default {
  mixins: [SimpleTimer],
  props: {
    tournament: Object,
    simple: Boolean,
    isIngamePopup: {
      type: Boolean,
      default: false
    },
    sized: {
      type: Boolean,
      default: true
    }
  },
  name: 'Timer',
  computed: {
    isEnded: function isEnded() {
      if (!this.tournament.end) return true;
      return new Date().getTime() > new Date(this.tournament.end).getTime();
    },
    emptyState: function emptyState() {
      return "00 ".concat(this.$t('date.minutes'), " 00 ").concat(this.$t('date.seconds'));
    },
    date: function date() {
      var d = this.tournament.startDate.split('-');
      var endedDay = new Date(Date.UTC(d[0], +d[1] - 1, d[2]));
      var format24 = this.$q.lang.date.format24;
      var start = new Date(this.tournament.end);
      var end = new Date(this.tournament.end);
      var startString = start.toLocaleString(this.$q.lang.isoName, {
        hour: 'numeric',
        minute: 'numeric',
        format24: format24
      });
      var endString = endedDay.toLocaleString(this.$q.lang.isoName, {
        day: 'numeric',
        month: 'short'
      });
      return {
        startString: startString,
        endString: endString,
        start: start,
        end: end
      };
    },
    time: function time() {
      if (this.isEnded) return "".concat(this.getDay(), ", ").concat(this.date.endString);
      return this.parseTime();
    },
    simpleTime: function simpleTime() {
      if (new Date().getTime() > new Date(this.tournament.end).getTime()) return this.emptyState;
      return this.parseTime();
    }
  },
  methods: {
    getDay: function getDay() {
      var today = new Date();
      var inputDate = new Date(this.tournament.startDate).toDateString();
      var yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      var tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (inputDate === yesterday.toDateString()) return this.$t('tournaments.timer.yesterday');
      if (inputDate === tomorrow.toDateString()) return this.$t('tournaments.timer.tomorrow');
      if (inputDate === today.toDateString()) return this.$t('tournaments.timer.today');
      return this.$t('tournaments.timer.earlier');
    }
  }
};