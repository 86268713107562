import { render, staticRenderFns } from "./StepAccount.vue?vue&type=template&id=28ed3228&scoped=true&lang=pug&"
import script from "./StepAccount.vue?vue&type=script&lang=js&"
export * from "./StepAccount.vue?vue&type=script&lang=js&"
import style0 from "./StepAccount.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./StepAccount.vue?vue&type=style&index=1&id=28ed3228&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ed3228",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QList from 'quasar/src/components/item/QList.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QField,QIcon,QItem,QItemSection,QCheckbox,QItemLabel,QStepperNavigation,QBtn,QSpinnerDots,QList,QTab});
