import { render, staticRenderFns } from "./ProfileDocuments.vue?vue&type=template&id=f2435b3e&scoped=true&lang=pug&"
import script from "./ProfileDocuments.vue?vue&type=script&lang=js&"
export * from "./ProfileDocuments.vue?vue&type=script&lang=js&"
import style0 from "./ProfileDocuments.vue?vue&type=style&index=0&id=f2435b3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2435b3e",
  null
  
)

export default component.exports
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QUploaderAddTrigger from 'quasar/src/components/uploader/QUploaderAddTrigger.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QUploader,QUploaderAddTrigger,QCircularProgress,QBtn,QIcon,QField});
