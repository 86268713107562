import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.replace.js";
export default {
  computed: {
    getMode: function getMode() {
      return this.$store.getters['cash/wrapperMode'];
    },
    disableCloseByCover: function disableCloseByCover() {
      return this.$store.getters['cash/disableCloseByCover'];
    },
    isMobileApplication: function isMobileApplication() {
      return document.location.pathname.includes('cashIframe');
    }
  },
  methods: {
    modalCommandClose: function modalCommandClose() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        returnToGame: false
      },
          returnToGame = _ref.returnToGame;

      this.$store.dispatch('cash/setIsSuccessPopup', false); // Restore z-index

      if (this.$store.getters['cash/isModal']) {
        document.location.replace(this.$store.getters['cash/modalCommandClose']);
        return;
      }

      this.$router.push('/');

      if (returnToGame) {
        window.dispatchEvent(new CustomEvent('CASH-RETURN-TO-GAME'));
      }
    }
  }
};