export default [{
  id: 'fb',
  name: 'Facebook',
  className: 'social-icons__fb',
  href: '/login/facebook/',
  show: 'all',
  title: 'Facebook',
  icon: 'facebook'
}, {
  id: 'gp',
  name: 'Google',
  className: 'social-icons__gp',
  href: '/login/Google/',
  show: 'all',
  title: 'Google',
  icon: 'google'
}];