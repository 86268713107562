import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'InsufficientPromo',
  props: {
    promo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      isActivePromo: false
    };
  },
  computed: {
    showBadge: function showBadge() {
      var _this$promo, _this$promo$promoOffe, _this$promo2, _this$promo2$promoOff;

      return ((_this$promo = this.promo) === null || _this$promo === void 0 ? void 0 : (_this$promo$promoOffe = _this$promo.promoOfferPreset) === null || _this$promo$promoOffe === void 0 ? void 0 : _this$promo$promoOffe.badgeLabel) && ((_this$promo2 = this.promo) === null || _this$promo2 === void 0 ? void 0 : (_this$promo2$promoOff = _this$promo2.promoOfferPreset) === null || _this$promo2$promoOff === void 0 ? void 0 : _this$promo2$promoOff.imageBadge);
    },
    badgeLabel: function badgeLabel() {
      var _this$promo3, _this$promo3$promoOff, _this$promo4, _this$promo4$promoOff;

      return (_this$promo3 = this.promo) !== null && _this$promo3 !== void 0 && (_this$promo3$promoOff = _this$promo3.promoOfferPreset) !== null && _this$promo3$promoOff !== void 0 && _this$promo3$promoOff.badgeLabel ? JSON.parse((_this$promo4 = this.promo) === null || _this$promo4 === void 0 ? void 0 : (_this$promo4$promoOff = _this$promo4.promoOfferPreset) === null || _this$promo4$promoOff === void 0 ? void 0 : _this$promo4$promoOff.badgeLabel) : '';
    },
    badgePercent: function badgePercent() {
      var _this$badgeLabel;

      return (_this$badgeLabel = this.badgeLabel) === null || _this$badgeLabel === void 0 ? void 0 : _this$badgeLabel.percent;
    },
    badgeText: function badgeText() {
      var _this$badgeLabel2;

      return (_this$badgeLabel2 = this.badgeLabel) === null || _this$badgeLabel2 === void 0 ? void 0 : _this$badgeLabel2.text;
    },
    usualPrice: function usualPrice() {
      var _this$promo5, _this$promo5$promoOff, _this$promo6, _this$promo6$promoOff;

      return (_this$promo5 = this.promo) !== null && _this$promo5 !== void 0 && (_this$promo5$promoOff = _this$promo5.promoOfferPreset) !== null && _this$promo5$promoOff !== void 0 && _this$promo5$promoOff.usualPrice ? Number((_this$promo6 = this.promo) === null || _this$promo6 === void 0 ? void 0 : (_this$promo6$promoOff = _this$promo6.promoOfferPreset) === null || _this$promo6$promoOff === void 0 ? void 0 : _this$promo6$promoOff.usualPrice).toFixed(2) : null;
    }
  },
  methods: {
    openPromo: function openPromo() {
      this.isActivePromo = true;
      this.$emit('openCash');
    }
  }
};