import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mask } from 'vue-the-mask';
export default {
  name: 'ProfileItem',
  props: {
    isNumber: {
      type: Boolean,
      default: false
    },
    refs: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    fieldName: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    phone: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      default: false
    },
    value: [String, Number]
  },
  directives: {
    mask: mask
  },
  data: function data() {
    return {
      errorMessage: null,
      changedFields: {}
    };
  },
  watch: {
    error: function error() {
      this.errorMessage = this.error;
    }
  },
  computed: {
    inputValue: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    resetFieldError: function resetFieldError(field) {
      if (!this.changedFields[field]) {
        window.$store.gaCash.changeForm({
          formName: 'deposit',
          formField: field
        });
        this.changedFields[field] = true;
      }

      this.errorMessage = null;
    }
  }
};