import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'RacesGamesModal',
  computed: {
    games: function games() {
      return this.$store.getters['games/slots'];
    },
    race: function race() {
      return this.$store.getters['tournament/tournament'];
    },
    raceGames: function raceGames() {
      var _this$race, _this$race2;

      return (_this$race = this.race) !== null && _this$race !== void 0 && _this$race.gamesIds ? this.getGames((_this$race2 = this.race) === null || _this$race2 === void 0 ? void 0 : _this$race2.gamesIds) : [];
    },
    deviceType: function deviceType() {
      var _this$$store$getters$, _this$$store$getters$2;

      return (_this$$store$getters$ = this.$store.getters['device/info']) === null || _this$$store$getters$ === void 0 ? void 0 : (_this$$store$getters$2 = _this$$store$getters$.device) === null || _this$$store$getters$2 === void 0 ? void 0 : _this$$store$getters$2.type;
    }
  },
  methods: {
    getGames: function getGames(arr) {
      var _this = this;

      var filtered = [];

      if (this.games) {
        arr.forEach(function (game) {
          var item = _this.games.find(function (g) {
            return g.id === game;
          });

          if (!item) return;
          filtered.push(item);
        });
        return filtered;
      }

      return filtered;
    }
  },
  mounted: function mounted() {}
};