import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export default {
  methods: {
    formatNumber: function formatNumber(value, isFloat, round) {
      if (value == null) return '';
      var sum = Number(value).toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1,');

      if (isFloat && round) {
        return Math.round(value);
      }

      if (isFloat && !sum.includes('.')) return "".concat(sum, ".00");
      return sum;
    }
  }
};