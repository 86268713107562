import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
export default {
  data: function data() {
    return {
      popupName: ''
    };
  },
  computed: {
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    profileRequiredFields: function profileRequiredFields() {
      return this.$store.getters['player/profileRequiredFields'];
    },
    profileCompleted: function profileCompleted() {
      return Object.values(this.profileRequiredFields).every(function (item) {
        return item;
      });
    },
    phoneConfirmed: function phoneConfirmed() {
      return this.appMain.confirmed;
    },
    emailConfirmed: function emailConfirmed() {
      return this.appMain.emailConfirmed;
    },
    fbCompleted: function fbCompleted() {
      var _this$appMain, _this$appMain$activeS;

      return (_this$appMain = this.appMain) === null || _this$appMain === void 0 ? void 0 : (_this$appMain$activeS = _this$appMain.activeSocialAccounts) === null || _this$appMain$activeS === void 0 ? void 0 : _this$appMain$activeS.find(function (item) {
        return item === 'facebook';
      });
    }
  },
  methods: {
    goToNextStep: function goToNextStep() {
      var popupData = {};

      if (!this.profileCompleted) {
        this.popupName = 'ProfileForm';
      } else if (!this.phoneConfirmed || !this.emailConfirmed) {
        this.popupName = 'Confirmation';
        popupData.type = !this.phoneConfirmed ? 'phone' : 'email';
        popupData.signUpBonusFlow = true;
      } else if (!this.fbCompleted) {
        this.popupName = 'AddFacebook';
      }

      if (this.popupName) this.openPopup(this.popupName, popupData);
    },
    openPopup: function openPopup(popupName, popupData) {
      this.$store.dispatch("modals/set".concat(popupName), _objectSpread({
        isOpen: true
      }, popupData));
    }
  }
};