export default {
  computed: {
    finOperations: function finOperations() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['cash/appCash']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.finOperations;
    },
    isFinOperationsAvailable: function isFinOperationsAvailable() {
      var _this$finOperations;

      return (_this$finOperations = this.finOperations) === null || _this$finOperations === void 0 ? void 0 : _this$finOperations.isAvailable;
    },
    finOperationsAccountStatus: function finOperationsAccountStatus() {
      var _this$finOperations2;

      return (_this$finOperations2 = this.finOperations) === null || _this$finOperations2 === void 0 ? void 0 : _this$finOperations2.accountStatus;
    }
  }
};