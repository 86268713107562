import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import Auth from '@/mixins/Auth';
import StepAccount from '@/components/Auth/StepAccount';
export default {
  name: 'SignUp',
  mixins: [Base, Auth],
  components: {
    'app-step-account': StepAccount
  },
  data: function data() {
    return {
      promoCodeSuccessState: false,
      invitedData: {
        name: '',
        surname: ''
      }
    };
  },
  methods: {
    handlePromoCodeState: function handlePromoCodeState(data) {
      if (!data) {
        this.invitedData = {
          firstName: '',
          lastName: ''
        };
        this.promoCodeSuccessState = false;
      } else {
        this.invitedData = data;
        this.promoCodeSuccessState = true;
        var notifyPosition = this.isDesktop ? 'bottom-right' : 'top';
        this.$q.notify({
          message: this.getTitle,
          color: 'success',
          position: notifyPosition,
          timeout: 3000,
          classes: 'notificaton-box notificaton-box--qualified'
        });
      }
    }
  },
  computed: {
    getFullName: function getFullName() {
      return "".concat(this.invitedData.name, "'s ").concat(this.invitedData.surname.substring(0, 1), ".");
    },
    getTitle: function getTitle() {
      if (this.invitedData.name !== '' && this.invitedData.surname !== '') {
        return "".concat(this.$t('registration.fullNamePrefix'), " ").concat(this.getFullName, " ").concat(this.$t('registration.fullNamePostfix'));
      }

      return this.$t('registration.promoDefaultTitle');
    },
    locationAuth: function locationAuth() {
      return this.$store.getters['auth/locationAuth'];
    }
  },
  mounted: function mounted() {
    this.$store.gaRegister.start({
      location: this.locationAuth
    });
  },
  destroyed: function destroyed() {
    this.$store.dispatch('auth/setLocationAuth', '');
  }
};