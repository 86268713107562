import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
export default {
  methods: {
    getLocation: function getLocation() {
      var fraudDetector = window.$store.getters['player/profile'].fraudDetector;

      if (fraudDetector && !fraudDetector.verificationCompleted && navigator.geolocation) {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(function (position) {
            resolve(position);
          }, function (error) {
            reject(error);
          });
        });
      }

      return Promise.reject();
    }
  }
};