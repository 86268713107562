//
//
//
//
export default {
  props: {
    deadline: {
      type: String
    }
  },
  data: function data() {
    return {
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      },
      finished: false
    };
  },
  computed: {
    emptyState: function emptyState() {
      return "00 ".concat(this.$t('date.hours'), " 00 ").concat(this.$t('date.minutes'), " 00 ").concat(this.$t('date.seconds'));
    },
    getFormattedTimer: function getFormattedTimer() {
      var absoluteDays = Math.floor(this.days);
      var d = absoluteDays > 9 ? absoluteDays : "0".concat(absoluteDays);
      var absoluteHours = Math.floor(this.hours);
      var h = absoluteHours > 9 ? absoluteHours : "0".concat(absoluteHours);
      var absoluteMinutes = Math.floor(this.minutes);
      var m = absoluteMinutes > 9 ? absoluteMinutes : "0".concat(absoluteMinutes);
      var absoluteSeconds = Math.floor(this.seconds);
      var s = absoluteSeconds > 9 ? absoluteSeconds : "0".concat(absoluteSeconds);
      if (this.finished) return this.emptyState;
      if (d > 0) return "".concat(d, " ").concat(this.$t('date.days'), " ").concat(h, " ").concat(this.$t('date.hours'), " ").concat(m, " ").concat(this.$t('date.minutes'));
      if (h > 0) return "".concat(h, " ").concat(this.$t('date.hours'), " ").concat(m, " ").concat(this.$t('date.minutes'), "  ").concat(s, " ").concat(this.$t('date.seconds'));
      return "".concat(m, " ").concat(this.$t('date.minutes'), " ").concat(s, " ").concat(this.$t('date.seconds'));
    }
  },
  methods: {
    updateDiffs: function updateDiffs() {
      var diff = new Date(this.deadline).getTime() - Date.now();

      if (diff <= 0) {
        this.finished = true;
        this.$emit('stop-timer');
        clearInterval(this.interval);
        return;
      }

      this.days = Math.floor(diff / this.intervals.day);
      diff -= this.days * this.intervals.day;
      this.hours = Math.floor(diff / this.intervals.hour);
      diff -= this.hours * this.intervals.hour;
      this.minutes = Math.floor(diff / this.intervals.minute);
      diff -= this.minutes * this.intervals.minute;
      this.seconds = Math.floor(diff / this.intervals.second);
    }
  },
  mounted: function mounted() {
    var _this = this;

    if (new Date().getTime() < new Date(this.deadline).getTime()) {
      this.interval = setInterval(function () {
        _this.updateDiffs();
      }, 1000);
      this.updateDiffs();
    }
  },
  destroyed: function destroyed() {
    clearInterval(this.interval);
  }
};