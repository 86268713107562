import Axios from 'axios';
var api = process.env.SERVER ? Axios.create({
  baseURL: "http://localhost:".concat(process.env.PORT),
  withCredentials: true
}) : Axios.create({
  withCredentials: true
});
export default {
  instance: api,

  /**
   * GET Получить токен
   *
   */
  getVerificationToken: function getVerificationToken(reason) {
    return api.get("/rest/player/verification/token/?reason=".concat(reason));
  }
};