import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isError: {
      type: [Boolean, String],
      default: false
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: 'Неверный код подтверждения'
    },
    codeLength: {
      type: Number,
      default: 4
    },
    value: {
      type: [Number, String],
      default: ''
    },
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      code: this.getCodeArrayFromValue(this.value),
      lastCode: this.getCodeArrayFromValue(this.value)
    };
  },
  computed: {
    resultCode: function resultCode() {
      return this.code.join('');
    }
  },
  methods: {
    checkCodeInputs: function checkCodeInputs() {
      var filledAll = true;
      if (this.code.length < 4) return false;
      this.code.forEach(function (code) {
        if (code === null || code === undefined || code === '') {
          filledAll = false;
        }
      });
      return filledAll;
    },
    getCodeArrayFromValue: function getCodeArrayFromValue(value) {
      return value.toString().slice(0, this.codeLength).split('');
    },
    inputOnFocus: function inputOnFocus(event, i) {
      if (event.keyCode === 8 || event.keyCode === 46) {
        this.onDelete(i);
        return;
      }

      if (!this.checkCodeInputs()) {
        // eslint-disable-next-line no-plusplus
        for (var index = 0; index < this.codeLength; index++) {
          if (!this.$refs["code-input-".concat(index)][0].value) {
            this.setFocus(index);
            break;
          }
        }
      } else this.setFocus(this.code.length - 1);

      var value = this.code[i];
      this.lastCode[i] = value !== '' ? value : null;
      this.$set(this.code, i, '');
    },
    onDelete: function onDelete(i) {
      var _this = this;

      var prevIndex = i - 1;
      if (prevIndex < 0) prevIndex = 0;

      if (this.lastCode[i]) {
        this.$set(this.lastCode, i, null);
        this.$set(this.code, i, null);
      } else {
        this.$set(this.code, prevIndex, null);
        this.$nextTick(function () {
          return _this.focusOnPrevField(i);
        });
      }
    },
    inputOnBlur: function inputOnBlur(event, i) {
      var value = event.target.value;
      if (value === '' && this.lastCode[i]) this.$set(this.code, i, this.lastCode[i]);
    },
    enterValue: function enterValue(value, i) {
      var _this2 = this;

      this.$set(this.code, i, value);
      if (value) this.$nextTick(function () {
        return _this2.focusOnNextField(i, true);
      });
    },
    getCode: function getCode() {
      return this.code.join('');
    },
    setFocus: function setFocus(index) {
      this.$refs["code-input-".concat(index)][0].focus();
    },
    focusOnNextField: function focusOnNextField(currentIndex, isTypingValue) {
      if (currentIndex < this.codeLength - 1) this.setFocus(currentIndex + 1);else if (isTypingValue) this.$refs["code-input-".concat(currentIndex)][0].blur();
    },
    focusOnPrevField: function focusOnPrevField(currentIndex) {
      if (currentIndex > 0) this.setFocus(currentIndex - 1);
    },
    validate: function validate() {
      this.$refs.codeInput.validate();
    },
    reset: function reset() {
      this.$refs.codeInput.resetValidation();
    },
    clearCode: function clearCode() {
      this.code.splice(0, this.code.length);
      console.log(this.code);
    }
  },
  watch: {
    resultCode: function resultCode() {
      if (this.resultCode.length === this.codeLength && this.resultCode !== this.lastCode.join('')) {
        this.$emit('filled', this.resultCode);
      }
    },
    value: function value() {
      this.code = this.getCodeArrayFromValue(this.value);
    },
    isError: function isError() {
      this.validate();
      this.clearCode();
    }
  }
};