//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TournamentMixin from '@/mixins/TournamentMixin';
export default {
  name: 'RacesPrizesModal',
  mixins: [TournamentMixin],
  props: {
    tournament: {
      type: Object,
      default: function _default() {}
    }
  }
};