import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TournamentMixin from '@/mixins/TournamentMixin';
export default {
  name: 'RacesLeaderboardModal',
  mixins: [TournamentMixin],
  props: {
    tournament: {
      type: Object,
      default: function _default() {}
    },
    gaRaceData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isAbsolute: false
    };
  },
  computed: {
    isRaceSubscribed: function isRaceSubscribed() {
      return this.tournament.isSubscribed || this.isSubscribed;
    }
  },
  methods: {
    setIsAbsolute: function setIsAbsolute() {
      var _document$getElements, _document$getElements2;

      var list = this.$refs.racesLeaderboardList;
      var listEndY = (list === null || list === void 0 ? void 0 : list.getBoundingClientRect().y) + (list === null || list === void 0 ? void 0 : list.offsetHeight);
      var elY = (_document$getElements = document.getElementsByClassName('races-leaderboard__row--active')[0]) === null || _document$getElements === void 0 ? void 0 : _document$getElements.getBoundingClientRect().y;
      var childElHeight = (_document$getElements2 = document.getElementsByClassName('races-leaderboard__wrapper--active')[0]) === null || _document$getElements2 === void 0 ? void 0 : _document$getElements2.offsetHeight;
      this.isAbsolute = elY + childElHeight > listEndY;
    },
    getPrize: function getPrize(winner) {
      return winner.prize || winner.prizeActive || 0;
    }
  },
  mounted: function mounted() {
    this.$store.gaRace.clickButton(_objectSpread({
      location: this.isRaceSubscribed ? 'PLAY NOW' : 'JOIN NOW',
      buttonName: 'view_leaderboard',
      formName: 'Race'
    }, this.gaRaceData));
    setTimeout(this.setIsAbsolute, 200);
    this.$refs.racesLeaderboardList.addEventListener('scroll', this.setIsAbsolute);
  }
};