//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cash from '@modules/cash/mixins/cash';
export default {
  name: 'Header',
  mixins: [cash],
  props: {
    title: {
      type: String,
      default: ''
    },
    isVerify: {
      type: Boolean,
      default: false
    },
    showGoToDeposit: {
      type: Boolean,
      default: false
    },
    showFeedbackPopup: {
      type: Boolean,
      default: false
    },
    showGoBack: {
      type: Boolean,
      default: false
    },
    showBackProviders: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    redeem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    orderSummaryBonus: function orderSummaryBonus() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['cash/orderSummary']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.bonus;
    }
  },
  methods: {
    close: function close() {
      this.$store.dispatch('deposit/resetCashData'); // if (this.showFeedbackPopup && !this.isMobileApplication) this.$emit('openFeedback');
      // else this.modalCommandClose();

      this.modalCommandClose();
    },
    toDeposit: function toDeposit() {
      this.$router.push('/cash/deposit/');
    },
    goBack: function goBack() {
      this.$emit('backToStart');
    },
    backToProviders: function backToProviders() {
      if (this.orderSummaryBonus) this.$store.dispatch('cash/setOrderSummaryBonus', null);
      this.$emit('backToProviders');
    },
    backToRedeem: function backToRedeem() {
      this.$emit('backToRedeem');
    },
    backBth: function backBth() {
      if (this.showBackProviders) {
        this.backToProviders();
      } else if (this.showGoBack) {
        this.goBack();
      } else if (this.redeem) {
        this.backToRedeem();
      } else if (this.showGoToDeposit) {
        this.toDeposit();
      } else if (this.isVerify) {
        this.$router.push('/cash/deposit-by-money/');
        this.$store.dispatch('deposit/setIsShowBackBtn', true);
      }
    }
  }
};