//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#CB41A3'
    }
  }
};