//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VerificationFailed',
  props: {
    isLimitReason: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeVerification: function closeVerification() {
      this.$emit('closeVerify');
    },
    openSupport: function openSupport() {
      window.$store.$support.open();
      window.$store.gaCash.clickButton({
        location: 'verification_info',
        buttonName: 'support'
      });
    }
  }
};