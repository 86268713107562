import "core-js/modules/es6.function.name.js";
export default {
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    isLoginFlow: function isLoginFlow() {
      if (!this.loggedIn) return false;
      return this.$store.getters['auth/isLoginFlow'];
    },
    cis: function cis() {
      return this.$store.getters['player/profile'].cis;
    },
    ipCountry: function ipCountry() {
      return this.$store.getters['player/ipCountry'];
    },
    currencies: function currencies() {
      return this.$store.getters['player/currencies'];
    },
    countries: function countries() {
      return this.$store.getters['player/countries'];
    },
    step: function step() {
      return this.$store.getters['auth/activeStep'];
    },
    showTabs: function showTabs() {
      return this.$store.getters['auth/showTabs'];
    },
    warningMsg: function warningMsg() {
      return this.$store.getters['auth/warningMsg'];
    },
    profileCompleted: function profileCompleted() {
      if (this.cis && !this.isLoginFlow) return true;
      if (this.cis && this.profile.email && this.isLoginFlow) return true;

      if (!this.profile.nickname || !this.profile.name || !this.profile.surname || !this.profile.address || !this.profile.city || !this.profile.zip || !this.profile.birthDay || !this.profile.birthMonth || !this.profile.birthYear || !this.profile.email && this.isLoginFlow) {
        return false;
      }

      return true;
    }
  },
  methods: {
    resetFieldError: function resetFieldError(field) {
      this.$refs[field].resetValidation();
    },
    closeWarningMsg: function closeWarningMsg() {
      this.$store.commit('auth/setWarningMsg', false);
    },
    showWarningMsg: function showWarningMsg() {
      this.$store.commit('auth/setWarningMsg', true);
    },
    closeModal: function closeModal() {
      this.$store.commit('auth/resetAuthState');
      this.$store.dispatch('player/logout');
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: false
      });
    },
    closeModalSuccess: function closeModalSuccess() {
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: false
      });
      this.$store.dispatch('auth/resetAuthState');
      this.$store.dispatch('player/init');
      this.$store.dispatch('auth/removeLoginFlowCookie');
    }
  }
};