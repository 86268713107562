//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
export default {
  name: 'PromotionEmailPopup',
  mixins: [Base],
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    }
  },
  methods: {
    close: function close() {
      this.$emit('closePopup');
    }
  }
};